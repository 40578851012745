import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './footer.css';

const Footer = (props) => {
  const [email, setEmail] = useState(''); // State to store the email input

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit behavior
  
    // Validate email
    const emailDomain = email.split('@')[1];
    const validDomains = ['gmail.com', 'icloud.com'];
    if (!email || !validDomains.includes(emailDomain)) {
      alert('Please enter a valid email address from @gmail.com or @icloud.com');
      return;
    }
  
    // POST request to the API endpoint
    try {
      const response = await fetch('https://api.promiselogs.org/newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (response.ok) {
        alert('Thank you for subscribing!');
        setEmail(''); // Clear the input after successful submission
      } else {
        throw new Error('Failed to subscribe. Please try again later.');
      }
    } catch (error) {
      alert(error.message);
    }
  };
  

  return (
    <footer className="footer-footer1 thq-section-padding">
      <div className="footer-max-width thq-section-max-width">
        <div className="footer-content">
          <div className="footer-newsletter">
            <strong className="thq-body-large footer-column1-title"><img
              alt="promiselogs logo"
              src="./assets/images/promise-tracker-logo.png"
              className="footer-image1"
            /> Promiselogs</strong>
            <span className="thq-body-small">
              Subscribe to our newsletter for the latest promises made by the Kenyan government</span>
            <form className="footer-actions" onSubmit={handleSubmit}>
              <div className="footer-form">
                <div className="footer-container">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="footer-text-input thq-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button type="submit" className="thq-button-outline footer-button">
                  <span className="thq-body-small">{props.action1}</span>
                </button>
              </div>
            </form>
            <span className="footer-content2 thq-body-small">
              {/*props.content2 */}
            </span>
          </div>
          <div className="footer-links">
            <div className="footer-column1">
              <strong className="thq-body-large footer-column1-title">
                Profiles
              </strong>
              <div className="footer-footer-links">
                <span className="thq-body-small"><a href="/mp/elachi-beatrice-kadeveresia">National Assembly</a></span>
                <span className="thq-body-small">Senate</span>
                <span className="thq-body-small">Governors</span>
                <span className="thq-body-small">Cabinet Secretaries</span>
                <span className="thq-body-small">Other Civil Servants</span>

              </div>
            </div>
            <div className="footer-column2">
              <strong className="thq-body-large footer-column2-title">
                Tracking
              </strong>
              <div className="footer-footer-links1">
                <span className="thq-body-small">Promises</span>
                <span className="thq-body-small">Bills</span>
                <span className="thq-body-small">Scandals</span>
                <span className="thq-body-small">Extrajudicial Killings</span>
                <span className="thq-body-small"><a href="/trips/william-ruto-trip-to-world-bank-africa-human-capital-heads-of-state-summit">International Trips</a></span>
              </div>
            </div>
            <div className="footer-column3">
              <strong className="thq-body-large footer-social-link1-title">
                Other Links
              </strong>
              <div className="footer-footer-links1">
                <span className="thq-body-small">Developer API</span>
                <span className="thq-body-small">Counties</span>
                <span className="thq-body-small">Constituencies</span>
                <span className="thq-body-small"><a href="https://t.me/promiselogs"> Telegram Channel</a></span>
                <span className="thq-body-small"><a href="https://twitter.com/promiselogs">Follow us on Twitter</a></span>
              </div>
              
            </div>
          </div>
        </div>
        <div className="footer-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer-row">
            <span className="thq-body-small">Maintained by <a href='https://twitter.com/shadrac_matata'>Kisamba</a></span>
            <div className="footer-footer-links2">
              <span className="thq-body-small">{props.privacyLink}</span>
              <span className="thq-body-small">{props.termsLink}</span>

            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  column2Title: 'Quick Links',
  link7: 'Crowdsourcing Form',
  logoAlt: 'Promises Listing Logo',
  link4: 'Industries',
  socialLinkTitleCategory: 'Connect with Us',
  content3: "",
  link6: 'Sign Up',
  link1: 'Home',
  action1: 'Subscribe to Newsletter',
  cookiesLink: '/cookies-policy',
  logoSrc: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  link10: 'Privacy Policy',
  privacyLink: 'Privacy policy',
  link9: 'Terms of Use',
  link3: 'Regions',
  link8: 'References',
  link5: 'Promisers',
  column1Title: 'About Us',
  link2: 'Promises',
  content2: '© 2023 Promises Listing. All Rights Reserved.',
  termsLink: 'Terms of use',
}

Footer.propTypes = {
  column2Title: PropTypes.string,
  link7: PropTypes.string,
  logoAlt: PropTypes.string,
  link4: PropTypes.string,
  socialLinkTitleCategory: PropTypes.string,
  content3: PropTypes.string,
  link6: PropTypes.string,
  link1: PropTypes.string,
  action1: PropTypes.string,
  cookiesLink: PropTypes.string,
  logoSrc: PropTypes.string,
  link10: PropTypes.string,
  privacyLink: PropTypes.string,
  link9: PropTypes.string,
  link3: PropTypes.string,
  link8: PropTypes.string,
  link5: PropTypes.string,
  column1Title: PropTypes.string,
  link2: PropTypes.string,
  content2: PropTypes.string,
  termsLink: PropTypes.string,
}

export default Footer
