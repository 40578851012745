import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import Contact1 from './views/contact1'
import NotFound1 from './views/not-found'
import About from './views/about'
import Mp from './views/mp'
import Trip from './views/trip'


const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={About} exact path="/later" />
        <Route component={Mp} exact path="/mp/:slug" />
        <Route component={Trip} exact path="/trips/:slug" />
        <Route component={Contact1} exact path="/contact" />
        <Route component={NotFound1} exact path="/not-found" /> 
        <Route component={NotFound1} path="**" /> 
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
