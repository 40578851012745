import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Hero8 from '../components/hero8'
import Stats2 from '../components/stats2'
import Features11 from '../components/features11'
import Footer from '../components/footer'
import './about.css'
const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Spotless Hungry Crocodile</title>
        <meta property="og:title" content="About - Spotless Hungry Crocodile" />
      </Helmet>
      <Navbar></Navbar>
      <Hero8></Hero8>
      <Stats2></Stats2>
      <Features11></Features11>
    
      <Footer></Footer>
    </div>
  )
}

export default About
