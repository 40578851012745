import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './features1.css'

const Features1 = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className="thq-section-padding">
      <div className="features1-container1 thq-section-max-width">
        <div className="features1-image-container">
        {activeTab === 0 && (
  <video
    controls
    alt={props.feature1ImgAlt}
    src="./assets/videos/ruto-bans-public-contributions.mp4"
    className="features1-video thq-img-ratio-16-9"
  >
    Your browser does not support the video tag.
  </video>
)}

          {activeTab === 1 && (
           <video
           controls
           alt={props.feature1ImgAlt}
           src="./assets/videos/ruto-bans-public-contributions.mp4"
           className="features1-video thq-img-ratio-16-9"
         >
           Your browser does not support the video tag.
         </video>
          )}
          {activeTab === 2 && (
           <video
           controls
           alt={props.feature1ImgAlt}
           src="./assets/videos/ruto-bans-public-contributions.mp4"
           className="features1-video thq-img-ratio-16-9"
         >
           Your browser does not support the video tag.
         </video>
          )}
        </div>
        <div className="features1-tabs-menu">
          <div
            onClick={() => setActiveTab(0)}
            className="features1-tab-horizontal"
          >
            <div className="features1-divider-container">
              {activeTab === 0 && <div className="features1-container2"></div>}
            </div>
            <div className="features1-content">
              <h2 className="thq-heading-2">Public harambees banned</h2>
              <span className="thq-body-small">
             On July 5, 2024, President William Ruto directed that no state officer would be allowed to participate in fundraising activities. The president also directed the state officers to keep off philanthropic activities.
             "The Attorney General is hereby directed to prepare and submit legislation to this effect and develop a mechanism for structured and transparent contribution for public, charitable and philanthropic purposes," Ruto said. <sup><a href="https://www.the-star.co.ke/news/realtime/2024-07-11-ruto-bill-banning-state-officers-from-engaging-in-harambee-ready-for-publication/">[ 1 ]</a></sup>
              </span>
              <span>Through the Head of Public Service Mr. Felix Koskei, a memo issueing the directive was extended to to all state officers including those who are officers in Service commissions such as the Parliamentary, Judicial and Teachers Service Commission. The memo disclosed that the government would be collaborating with the Ethics and Anti-Corruption Commission to enforce a framework that will monitor the state officers.<sup><a href="https://www.kenyans.co.ke/news/102579-head-public-service-bans-all-state-officers-participating-harambees">[ 2 ]</a></sup>
                
              </span>
              <span>Barely 10 days later(July 15,2024), The president, in company of DP Rigathi Gachagua pledges to fund the remaining expenses for constructing a church during a Sunday service at AIPCA Church in Ndaragwa, Nyandarua county. <sup><a href="https://www.kenyans.co.ke/news/102722-ruto-oversee-church-completion-despite-state-ban-harambees?utm_source=Twitter&utm_medium=DK&utm_campaign=Promotion">[ 3 ]</a></sup></span>
            </div>
          </div>
          {/*
          <div
            onClick={() => setActiveTab(1)}
            className="features1-tab-horizontal1"
          >
            <div className="features1-divider-container1">
              {activeTab === 1 && <div className="features1-container3"></div>}
            </div>
            <div className="features1-content1">
              <h2 className="thq-heading-2">{props.feature2Title}</h2>
              <span className="thq-body-small">
                {props.feature2Description}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(2)}
            className="features1-tab-horizontal2"
          >
            <div className="features1-divider-container2">
              {activeTab === 2 && <div className="features1-container4"></div>}
            </div>
            <div className="features1-content2">
              <h2 className="thq-heading-2">{props.feature3Title}</h2>
              <span className="thq-body-small">
                {props.feature3Description}
              </span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

Features1.defaultProps = {
  feature1Description:
    'Contribute to the platform by submitting promises made.',
  feature2ImgAlt: 'Google Sign Up Image',
  feature1ImgAlt: 'Crowdsourcing Form Image',
  feature3Title: 'Advanced Filtering Options',
  feature3Description:
    'Filter promises by regions, industry sectors, and specific promisers.',
  feature2Title: 'Easy Sign Up',
  feature1ImgSrc:
    'https://images.unsplash.com/photo-1617896376265-28bcb35febf7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyMTQzNXw&ixlib=rb-4.0.3&q=80&w=1080',
  feature2Description: 'Sign up easily with Google account.',
  feature3ImgSrc:
    'https://images.unsplash.com/photo-1593371256584-ac70d0ab43d1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyMTQzNHw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1Title: 'Crowdsourcing Form',
  feature3ImgAlt: 'Filtering Options Image',
  feature2ImgSrc:
    'https://images.unsplash.com/photo-1618022325802-7e5e732d97a1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyMTQzNnw&ixlib=rb-4.0.3&q=80&w=1080',
}

Features1.propTypes = {
  feature1Description: PropTypes.string,
  feature2ImgAlt: PropTypes.string,
  feature1ImgAlt: PropTypes.string,
  feature3Title: PropTypes.string,
  feature3Description: PropTypes.string,
  feature2Title: PropTypes.string,
  feature1ImgSrc: PropTypes.string,
  feature2Description: PropTypes.string,
  feature3ImgSrc: PropTypes.string,
  feature1Title: PropTypes.string,
  feature3ImgAlt: PropTypes.string,
  feature2ImgSrc: PropTypes.string,
}

export default Features1
