import React from 'react';
import './hero8.css';

const TripHeader = ({ tripInfo }) => {
  // Helper function to extract the first YouTube or video link
  const getVideoLink = (links) => {
    const linkArray = links.split(',');
    return linkArray.find((link) => 
      link.includes('youtube.com') || link.includes('vimeo.com') || link.includes('.mp4')
    );
  };

  const videoLink = getVideoLink(tripInfo.trip_reference_links);

  // Function to generate the embed URL for YouTube or Vimeo
  const generateEmbedUrl = (link) => {
    if (link.includes('youtube.com')) {
      const videoId = link.split('v=')[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (link.includes('vimeo.com')) {
      const videoId = link.split('.com/')[1];
      return `https://player.vimeo.com/video/${videoId}`;
    }
    return link;
  };

  const embedUrl = videoLink ? generateEmbedUrl(videoLink) : null;

  return (
    <div className="hero8-header26 thq-section-padding">
      <div className="hero8-max-width thq-section-max-width thq-flex-column">
        <div className="hero8-column">
          <div className="hero8-content">
            <h2 className="thq-heading-2">{tripInfo.trip_title}</h2>
          </div>
        </div>
        {embedUrl ? (
          <div className="video-container">
            {embedUrl.includes('youtube.com') || embedUrl.includes('vimeo.com') ? (
              <iframe
                width="560"
                height="315"
                src={embedUrl}
                title={tripInfo.trip_title}
                className="features2-image thq-img-ratio-16-9"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <video
                controls
                poster={`https://promiselogs.org/assets/images/trips/${tripInfo.trip_avatar}`}
                className="thq-img-ratio-16-9"
              >
                <source src={videoLink} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ) : (
          <img
            alt={tripInfo.trip_title}
            src={`https://promiselogs.org/assets/images/trips/${tripInfo.trip_avatar}`}
            className="thq-img-ratio-16-9"
          />
        )}
      </div>
    </div>
  );
};

export default TripHeader;
