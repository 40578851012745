import React from 'react'

import PropTypes from 'prop-types'

import './features11.css'

const Features11 = (props) => {
  return (
    <div className="features11-layout251 thq-section-padding">
      <div className="features11-max-width thq-section-max-width">
        <div className="thq-flex-row features11-section-title">
          <div className="features11-column thq-flex-column">
            
            <h2 className="thq-heading-2 features11-text1">
              Overdue promises
            </h2>
          </div>
         
        </div>
        <div className="features11-content">
          <div className="features11-row thq-flex-row">
            <div className="features11-feature1 thq-flex-column">
              <img
                alt={props.feature1ImageAlt}
                src="./assets/images/ruto-free-call-promise.jpg"
                className="thq-img-ratio-4-3 features11-feature1-image"
              />
              <div className="features11-content1 thq-flex-column">
                <h3 className="thq-heading-3">Communication/ICT</h3>
                <span className="thq-body-small">
                  Free calls after 2022 elections
                </span>
              </div>
            </div>
            <div className="features11-feature2 thq-flex-column">
              <img
                alt={props.feature2ImageAlt}
                src="./assets/images/free-hospital-promise.png"
                className="thq-img-ratio-4-3 features11-feature2-image"
              />
              <div className="features11-content2 thq-flex-column">
                <h3 className="thq-heading-3">Healthcare</h3>
                <span className="thq-body-small">
                  Free access to healthcare from Jan 2024
                </span>
              </div>
            </div>
            <div className="features11-feature3 thq-flex-column">
              <img
                alt={props.feature3ImageAlt}
                src="./assets/images/free-gas.jpeg"
                className="thq-img-ratio-4-3 features11-feature3-image"
              />
              <div className="features11-content3 thq-flex-column">
                <h3 className="thq-heading-3">Energy</h3>
                <span className="thq-body-small">
                  KES 500/= Gas Cylinders
                </span>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}

Features11.defaultProps = {
  feature1Title: 'Promise Listing',
  feature2ImageAlt: 'Filtering Options Image',
  feature2Title: 'Filtering Options',
  feature1Description:
    'Browse through a wide range of promises made by individuals and organizations',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1629459322032-52044df58810?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDE2OTMxN3w&ixlib=rb-4.0.3&q=80&w=1080',
  feature3ImageSrc:
    'https://images.unsplash.com/photo-1591527292000-95f01a0d1496?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDE2OTMxNnw&ixlib=rb-4.0.3&q=80&w=1080',
  sectionDescription: 'Explore the key features of our platform',
  feature3Title: 'Contribution Hub',
  secondaryAction:
    'Choose from different pricing plans to access more features',
  feature2ImageSrc:
    'https://images.unsplash.com/photo-1652028379832-5d397e07f5a6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDE2OTMxNnw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1ImageAlt: 'Promise Listing Image',
  feature3Description:
    'Contribute your own promises to the platform and engage with the community',
  feature3ImageAlt: 'Contribution Hub Image',
  slogan: 'Discover, contribute, and filter promises with ease',
  mainAction: 'Sign up easily and start engaging with promises',
  sectionTitle: 'Related Promises',
  feature2Description:
    'Filter promises by regions, industry sectors, and specific promisers for targeted results',
}

Features11.propTypes = {
  feature1Title: PropTypes.string,
  feature2ImageAlt: PropTypes.string,
  feature2Title: PropTypes.string,
  feature1Description: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
  sectionDescription: PropTypes.string,
  feature3Title: PropTypes.string,
  secondaryAction: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature3Description: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
  slogan: PropTypes.string,
  mainAction: PropTypes.string,
  sectionTitle: PropTypes.string,
  feature2Description: PropTypes.string,
}

export default Features11
