import React from 'react';
import './stats2.css';

function formatText(text) {
  return text
    .split('-') // Split the string at each hyphen
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words with spaces
}

const TripBody = ({ tripInfo }) => {
  const referenceLinks = tripInfo.trip_reference_links
    ? tripInfo.trip_reference_links.split(',').map((link, index) => (
        <React.Fragment key={index}>
          <sup>
            <a href={link.trim()} target="_blank" rel="noopener noreferrer">
              [{index + 1}]
            </a>
          </sup>
          {' '}
        </React.Fragment>
      ))
    : null;

  return (
    <center>
      <div className="stats2-container thq-section-padding">
        <div className="stats2-max-width thq-section-max-width">
          <div className="stats2-container2 thq-flex-column">
            <h3 className="thq-heading-3">Trip Details</h3>
            <p className="thq-body-large">
              {`President William Ruto flew to ${tripInfo.city} ${tripInfo.country} for the ${tripInfo.trip_title} on ${tripInfo.trip_dates}. ${tripInfo.trip_notes} `}
              {referenceLinks && referenceLinks.length > 0 && referenceLinks}
            </p>
          </div>
        </div>
      </div>
    </center>
  );
};

export default TripBody;
