import React from 'react'

import PropTypes from 'prop-types'

import './hero8.css'

const MPHeader = ({ mpInfo }) => {
  return (
    <div className="hero8-header26 thq-section-padding">
      <div className="hero8-max-width thq-section-max-width thq-flex-column">
        
        <div className="hero8-column">
          <div className="hero8-content">
            <h2 className="thq-heading-2">{mpInfo.name}</h2>
           {/* <p className="hero8-text1 thq-body-large">{props.content1}</p>*/ }
            {/*<div className="hero8-actions">
              <button className="thq-button-filled hero8-button">
                <span className="thq-body-small">{props.action1}</span>
              </button>
              <button className="thq-button-outline hero8-button1">
                <span className="thq-body-small">{props.action2}</span>
              </button>
            </div>  */}
          </div>
        </div> 
        <img
          alt={mpInfo.name}
          src={`https://promiselogs.org/assets/images/${mpInfo.avatar}`}
          className="thq-img-ratio-16-9"
        />
      </div>
    </div>
  )
}



export default MPHeader
