import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './navbar.css';

const Navbar = (props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="navbar-container">
      <div data-thq="thq-navbar" className="navbar-navbar-interactive">
        <img alt={props.logoAlt} src={props.logoSrc} className="navbar-image1" />
        <div data-thq="thq-navbar-nav" className="navbar-desktop-menu">
          <nav className="navbar-links">
            <a href="/"><span className="thq-link thq-body-small">{props.link1}</span> </a>
            <a href="/about"> <span className="thq-link thq-body-small">{props.link2}</span></a>
            
            {/* Dropdown for Sector */}
            <div className="dropdown">
              <span className="thq-link thq-body-small dropdown-toggle">Sector</span>
              <div className="dropdown-content">
              <span className="thq-link thq-body-small">Agriculture</span>
                <span className="thq-link thq-body-small">Transport</span>
                <span className="thq-link thq-body-small">Education</span>
                <span className="thq-link thq-body-small">Technology</span>
                <span className="thq-link thq-body-small">Healthcare</span>
                <span className="thq-link thq-body-small">Banking & Finance</span>
                <span className="thq-link thq-body-small">Water</span>
                <span className="thq-link thq-body-small">Manufacturing</span>
                <span className="thq-link thq-body-small">Lands & Real Estate</span>
              </div>
            </div>
            
            {/* Dropdown for Region */}
            <div className="dropdown">
              <span className="thq-link thq-body-small dropdown-toggle">Region</span>
              <div className="dropdown-content">
                <span className="thq-link thq-body-small">Nairobi</span>
                <span className="thq-link thq-body-small">Coast</span>
                <span className="thq-link thq-body-small">Eastern</span>
                <span className="thq-link thq-body-small">Central</span>
                <span className="thq-link thq-body-small">North Eastern</span>
                <span className="thq-link thq-body-small">Nyanza</span>
                <span className="thq-link thq-body-small">Western</span>
                
              </div>
            </div>

            {/* Dropdown for Demographic */}
            <div className="dropdown">
              <span className="thq-link thq-body-small dropdown-toggle">{props.link5}</span>
              <div className="dropdown-content">
                <span className="thq-link thq-body-small">Women</span>
                <span className="thq-link thq-body-small">Elderly & PWD</span>
                <span className="thq-link thq-body-small">Youth</span>
              </div>
            </div>
             {/* Dropdown for public interests */}
            <div className="dropdown">
              <span className="thq-link thq-body-small dropdown-toggle">Public Interests</span>
              <div className="dropdown-content">
                <span className="thq-link thq-body-small">Controversial Bills</span>
                <span className="thq-link thq-body-small">Killer cops</span>
                <span className="thq-link thq-body-small">Incitement</span>
                <span className="thq-link thq-body-small">High profile cases</span>
                <span className="thq-link thq-body-small">State bloggers</span>

              </div>
            </div>
          </nav>
          <div className="navbar-buttons">
            <button className="navbar-action1 thq-button-animated thq-button-filled">
              {props.action1}
            </button>
            <button className="navbar-action2 thq-button-animated thq-button-outline">
              {props.action2}
            </button>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="navbar-burger-menu"
          onClick={toggleMobileMenu}
        >
          <svg viewBox="0 0 1024 1024" className="navbar-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div data-thq="thq-mobile-menu" className="navbar-mobile-menu">
          <div className="navbar-nav">
            <div className="navbar-top">
              <img alt={props.logoAlt} src={props.logoSrc} className="navbar-logo" />
              <div data-thq="thq-close-menu" className="navbar-close-menu" onClick={closeMobileMenu}>
                <svg viewBox="0 0 1024 1024" className="navbar-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            {/* Replicating main nav links for mobile */}
            <nav className="navbar-links1">
            <a href="/"><span className="thq-link thq-body-small">{props.link1}</span> </a>
             <a href="/about"> <span className="thq-link thq-body-small">{props.link2}</span></a>
              {/* Additional mobile dropdowns could be added here if needed */}
                {/* Dropdown for Sector */}
            <div className="dropdown">
              <span className="thq-link thq-body-small dropdown-toggle">Sector</span>
              <div className="dropdown-content">
              <span className="thq-link thq-body-small">Agriculture</span>
                <span className="thq-link thq-body-small">Transport</span>
                <span className="thq-link thq-body-small">Education</span>
                <span className="thq-link thq-body-small">Technology</span>
                <span className="thq-link thq-body-small">Healthcare</span>
                <span className="thq-link thq-body-small">Banking & Finance</span>
                <span className="thq-link thq-body-small">Water</span>
                <span className="thq-link thq-body-small">Manufacturing</span>
                <span className="thq-link thq-body-small">Lands & Real Estate</span>
              </div>
            </div>
            
            {/* Dropdown for Region */}
            <div className="dropdown">
              <span className="thq-link thq-body-small dropdown-toggle">Region</span>
              <div className="dropdown-content">
                <span className="thq-link thq-body-small">Nairobi</span>
                <span className="thq-link thq-body-small">Coast</span>
                <span className="thq-link thq-body-small">Eastern</span>
                <span className="thq-link thq-body-small">Central</span>
                <span className="thq-link thq-body-small">North Eastern</span>
                <span className="thq-link thq-body-small">Nyanza</span>
                <span className="thq-link thq-body-small">Western</span>
                
              </div>
            </div>

            {/* Dropdown for Demographic */}
            <div className="dropdown">
              <span className="thq-link thq-body-small dropdown-toggle">{props.link5}</span>
              <div className="dropdown-content">
                <span className="thq-link thq-body-small">Women</span>
                <span className="thq-link thq-body-small">Elderly & PWD</span>
                <span className="thq-link thq-body-small">Youth</span>
              </div>
            </div>
             {/* Dropdown for public interest */}
             <div className="dropdown">
              <span className="thq-link thq-body-small dropdown-toggle">Public Interests</span>
              <div className="dropdown-content">
                <span className="thq-link thq-body-small">Controversial Bills</span>
                <span className="thq-link thq-body-small">Killer cops</span>
                <span className="thq-link thq-body-small">Incitement</span>
                <span className="thq-link thq-body-small">High profile cases</span>
                <span className="thq-link thq-body-small">State bloggers</span>

              </div>
            </div>
            </nav>
          </div>
          <div className="navbar-buttons1">
            <button className="thq-button-filled">{props.action1}</button>
            <button className="thq-button-outline">{props.action2}</button>
          </div>
        </div>
      )}
    </header>
  );
}

Navbar.defaultProps = {
  link5: 'Demographic',
  link1: 'Home',
  action2: 'Add Promises',
  logoSrc: './assets/images/promise-tracker-logo.png', // Change this to your actual logo URL
  action1: 'Donate',
  link4: 'Region',
  link3: 'Sector',
  logoAlt: 'Promises Tracker',
  link2: 'About',
};

Navbar.propTypes = {
  link5: PropTypes.string,
  link1: PropTypes.string,
  action2: PropTypes.string,
  logoSrc: PropTypes.string,
  action1: PropTypes.string,
  link4: PropTypes.string,
  link3: PropTypes.string,
  logoAlt: PropTypes.string,
  link2: PropTypes.string,
};

export default Navbar;
