import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './features2.css'

const Features2 = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className="thq-section-padding">
      <div className="features2-container1 thq-section-max-width">
        <div className="features2-tabs-menu">
          <div
            onClick={() => setActiveTab(0)}
            className="features2-tab-horizontal"
          >
            <div className="features2-divider-container">
              {activeTab === 0 && <div className="features2-container2"></div>}
            </div>
            <div className="features2-content">
              <h2 className="thq-heading-2">Kenya Kwanza manifesto 2022</h2>
              <span className="thq-body-small">
                On Thursday, June 30, 2022, William Ruto  launched his five-point manifesto in Kasarani Stadium ahead of the August 9, 2022 polls. Dubbed ‘The Plan’, Ruto said Kenya’s economic challenges require immediate attention and one of his priorities as President is to revive the economy in a post-Covid era.
                <sup><a href="https://www.standardmedia.co.ke/the-standard/article/2001449245/the-plan-william-ruto-unveils-kenya-kwanza-manifesto">[ 1 ]</a></sup>
              </span>
              <span>
              According to William Ruto, economic policy should ensure capital in a manner that benefits the most amount of people and the country, at large. He cited a report which stated that only 19 million Kenyans are employed currently; out of which only 3 million (15%) are in formal jobs – or what he terms as the ‘lottery economy’ - while the other 16 million (85%) work in Micro. Small and Medium sized Enterprises (MSME).
              Ruto noted that the bottom-up economic model will be geared towards bringing down the cost of living,  eradicating hunger, creating jobs, expanding the tax base, improving the country’s foreign exchange balance as well as inclusive growth.
              <sup><a href="https://www.citizen.digital/news/the-six-pillars-of-dp-rutos-bottom-up-economic-model-and-why-he-believes-it-is-the-solution-n301225">[ 2 ]</a></sup>
              </span>
            </div>
          </div>
          {/*
          <div
            onClick={() => setActiveTab(1)}
            className="features2-tab-horizontal1"
          >
            <div className="features2-divider-container1">
              {activeTab === 1 && <div className="features2-container3"></div>}
            </div>
            <div className="features2-content1">
              <h2 className="thq-heading-2">Kenya Kwanza manifesto 2022</h2>
              <span className="thq-body-small">
                {props.feature2Description}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(2)}
            className="features2-tab-horizontal2"
          >
            <div className="features2-divider-container2">
              {activeTab === 2 && <div className="features2-container4"></div>}
            </div>
            <div className="features2-content2">
              <h2 className="thq-heading-2">{props.feature3Title}</h2>
              <span className="thq-body-small">
                {props.feature3Description}
              </span>
            </div>
          </div>
          */}
        </div>
        <div className="features2-image-container">
  {activeTab === 0 && (
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/a116HB_jsyw"
      title="Campaign"
      className="features2-image thq-img-ratio-16-9"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  )}
  {activeTab === 1 && (
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/a116HB_jsyw"
      title="Campaign"
      className="features2-image1 thq-img-ratio-16-9"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  )}
  {activeTab === 2 && (
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/a116HB_jsyw"
      title="Campaign"
      className="features2-image2 thq-img-ratio-16-9"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  )}
</div>


      </div>
    </div>
  )
}

Features2.defaultProps = {
  feature1ImgSrc:
    'https://images.unsplash.com/photo-1637989122130-41ef76e1f5e8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyMTQzNHw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1ImgAlt: 'feature 1',
  feature1Description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
  feature1Title: 'Feature #1',
  feature2Title: 'Crowdsourcing Form',
  feature2ImgSrc:
    'https://images.unsplash.com/photo-1580490124433-81e4d45ec469?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyMTQzN3w&ixlib=rb-4.0.3&q=80&w=1080',
  feature2ImgAlt: 'Crowdsourcing Form Image Alt',
  feature3Description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
  feature3ImgSrc:
    'https://images.unsplash.com/photo-1630940395913-a557ab7cd061?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyMTQzNXw&ixlib=rb-4.0.3&q=80&w=1080',
  feature2Description:
    'Submit promises made by individuals or organizations to be listed on the platform.',
  feature3ImgAlt: 'image',
  feature3Title: 'Feature #3',
}

Features2.propTypes = {
  feature1ImgSrc: PropTypes.string,
  feature1ImgAlt: PropTypes.string,
  feature1Description: PropTypes.string,
  feature1Title: PropTypes.string,
  feature2Title: PropTypes.string,
  feature2ImgSrc: PropTypes.string,
  feature2ImgAlt: PropTypes.string,
  feature3Description: PropTypes.string,
  feature3ImgSrc: PropTypes.string,
  feature2Description: PropTypes.string,
  feature3ImgAlt: PropTypes.string,
  feature3Title: PropTypes.string,
}

export default Features2
