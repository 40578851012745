import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Navbar from '../components/navbar';
import TripHeader from '../components/tripHeader';
import TripBody from '../components/tripBody';
import RelatedTrips from '../components/relatedTrips';
import Footer from '../components/footer';
import './about.css';
import preloaderGif from './preloader.gif'; // Ensure the path to your preloader gif is correct

const Trip = (props) => {
  const { slug } = useParams();
  const [tripData, setTripData] = useState(null);
  const [loading, setLoading] = useState(true); // New state for tracking loading status

  useEffect(() => {
    const fetchTripData = async () => {
      setLoading(true); // Start loading before fetching data
      try {
        const response = await fetch(`https://api.promiselogs.org/foreign_trips/${slug}`);
        const data = await response.json();
        console.log(data);
        setTripData(data);
      } catch (error) {
        console.error('Error fetching trip data:', error);
      } finally {
        setLoading(false); // End loading after fetching data
      }
    };

    fetchTripData();
  }, [slug]);

  return (
    <div className="about-container">
      <Helmet>
        <title>William Ruto's trip to {tripData ? tripData.trip_info[0].trip_title : ' - Promiselogs'} - Promiselogs</title>
        <meta name="description" content={tripData && tripData.trip_info && tripData.trip_info[0] ? `President William Ruto visited ${tripData.trip_info[0].trip_city},${tripData.trip_info[0].trip_country} for the ${tripData.trip_info[0].trip_title} on ${tripData.trip_info[0].trip_dates}` : 'Loading trip information...'} />
        <meta property="og:title" content={`William Ruto's trip to ${tripData ? tripData.trip_info[0].trip_title : ' - Promiselogs'} - Promiselogs`} />
        <meta property="og:description" content={tripData && tripData.trip_info && tripData.trip_info[0] ? `President William Ruto visited ${tripData.trip_info[0].trip_city},${tripData.trip_info[0].trip_country} for the ${tripData.trip_info[0].trip_title} on ${tripData.trip_info[0].trip_dates}` : 'Loading trip information...'} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://promiselogs.org/trips/${tripData && tripData.trip_info && tripData.trip_info[0] ? encodeURIComponent(tripData.trip_info[0].trip_slug) : ''}`} />
        <meta property="og:image" content={tripData && tripData.trip_info && tripData.trip_info[0] ? `https://promiselogs.org/assets/images/trips/${tripData.trip_info[0].trip_avatar}` : 'https://promiselogs.org/assets/images/trips/william-ruto-foreign-trips.jpeg'} />
        <meta property="og:image:height" content="1637" />
        <meta property="og:image:width" content="2560" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={`https://promiselogs.org/trips/${tripData && tripData.trip_info && tripData.trip_info[0] ? encodeURIComponent(tripData.trip_info[0].trip_slug) : ''}`} />
        <meta name="twitter:title" content={`William Ruto's trip to ${tripData ? tripData.trip_info[0].trip_title : ' - Promiselogs'} - Promiselogs`} />
        <meta name="twitter:description" content={tripData && tripData.trip_info && tripData.trip_info[0] ? `President William Ruto visited ${tripData.trip_info[0].trip_city},${tripData.trip_info[0].trip_country} for the ${tripData.trip_info[0].trip_title} on ${tripData.trip_info[0].trip_dates}` : 'Loading trip information...'} />
        <meta name="twitter:image:src" content={tripData && tripData.trip_info && tripData.trip_info[0] ? `https://promiselogs.org/assets/images/trips/${tripData.trip_info[0].trip_avatar}` : 'https://promiselogs.org/assets/images/trips/william-ruto-foreign-trips.jpeg'} />
        <meta name="twitter:image:width" content={2560} />
        <meta name="twitter:image:height" content={1637} />
      </Helmet>
      <Navbar />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <img src={preloaderGif} alt="Loading..." />
        </div>
      ) : (
        tripData && tripData.trip_info && tripData.trip_info[0] && (
          <>
            <TripHeader tripInfo={tripData.trip_info[0]} />
            <TripBody tripInfo={tripData.trip_info[0]} />
            <RelatedTrips relatedWith={tripData.related_with} />
          </>
        )
      )}
      <Footer />
    </div>
  );
};

export default Trip;
