import React from 'react'

import PropTypes from 'prop-types'

import './stats2.css'
function formatText(text) {
    return text
      .split('-') // Split the string at each hyphen
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words with spaces
  }

const MpBody = ({ mpInfo}) => {
  return (
    <center>
    <div className="stats2-container thq-section-padding">
      <div className="stats2-max-width thq-section-max-width">
        
        <div className="stats2-container2 thq-flex-column">
          
          <h3 className="thq-heading-3">Profile</h3>
          <p className="thq-body-large">Represents: {formatText(mpInfo.represents)} Constituency</p>
          <p className="thq-body-large">Party: {mpInfo.party} </p>
          <p className="thq-body-large">Education: {mpInfo.education}</p>
          <p className="thq-body-large">Age: {mpInfo.age}</p>
          <p className="thq-body-large">Mobile number: <a href={`tel:+${mpInfo.tel}`}>{mpInfo.tel}</a></p>
          <br></br>
          <h3 className="thq-heading-3">Bills and votes</h3>
          <i>Coming soon</i>
          <h3 className="thq-heading-3">Scandals</h3>
            <i>Coming soon</i>
          <h3 className="thq-heading-3">Businesses</h3>
          <i>Coming soon</i>
          {/*}
          <div className="stats2-container3 thq-grid-2">
            <div className="stats2-container4">
              <h2 className="thq-heading-2">{props.stat1}</h2>
              <span className="thq-body-small">{props.stat1Description}</span>
            </div>
            <div className="stats2-container5">
              <h2 className="thq-heading-2">{props.stat2}</h2>
              <span className="thq-body-small">{props.stat2Description}</span>
            </div>
          </div>
          <div className="stats2-container6 thq-grid-2">
            <div className="stats2-container7">
              <h2 className="thq-heading-2">{props.stat3}</h2>
              <span className="thq-body-small">{props.stat3Description}</span>
            </div>
            <div className="stats2-container8">
              <h2 className="thq-heading-2">{props.stat4}</h2>
              <span className="thq-body-small">{props.stat4Description}</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
    </center>
  )
}



export default MpBody
