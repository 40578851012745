import React from 'react'

import PropTypes from 'prop-types'

import './stats2.css'

const Stats2 = (props) => {
  return (
    <center>
    <div className="stats2-container thq-section-padding">
      <div className="stats2-max-width thq-section-max-width">
        
        <div className="stats2-container2 thq-flex-column">
          
          <h2 className="thq-heading-2">{props.heading1}</h2>
          <p className="thq-body-large">{props.content2}</p>
          <div className="stats2-container3 thq-grid-2">
            <div className="stats2-container4">
              <h2 className="thq-heading-2">{props.stat1}</h2>
              <span className="thq-body-small">{props.stat1Description}</span>
            </div>
            <div className="stats2-container5">
              <h2 className="thq-heading-2">{props.stat2}</h2>
              <span className="thq-body-small">{props.stat2Description}</span>
            </div>
          </div>
          <div className="stats2-container6 thq-grid-2">
            <div className="stats2-container7">
              <h2 className="thq-heading-2">{props.stat3}</h2>
              <span className="thq-body-small">{props.stat3Description}</span>
            </div>
            <div className="stats2-container8">
              <h2 className="thq-heading-2">{props.stat4}</h2>
              <span className="thq-body-small">{props.stat4Description}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </center>
  )
}

Stats2.defaultProps = {
  heading1: 'Customer results presented in a fashion way',
  stat1: '80%',
  content2:
    'Business improvements emphasized with numbers to increase creadibility',
  stat1Description: 'consectetur adipiscing elit,',
  stat2: '90%',
  content1: 'Customer Feedback',
  stat3Description: 'consectetur adipiscing elit,',
  stat4: '100%',
  stat2Description: 'consectetur adipiscing.',
  stat4Description: 'consectetur adipiscing.',
  stat3: '95%',
  image1Src:
    'https://images.unsplash.com/photo-1681168700204-15351e185e03?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDE2OTMxNnw&ixlib=rb-4.0.3&q=80&w=1080',
  image1Alt: 'image',
}

Stats2.propTypes = {
  heading1: PropTypes.string,
  stat1: PropTypes.string,
  content2: PropTypes.string,
  stat1Description: PropTypes.string,
  stat2: PropTypes.string,
  content1: PropTypes.string,
  stat3Description: PropTypes.string,
  stat4: PropTypes.string,
  stat2Description: PropTypes.string,
  stat4Description: PropTypes.string,
  stat3: PropTypes.string,
  image1Src: PropTypes.string,
  image1Alt: PropTypes.string,
}

export default Stats2
